document.addEventListener("turbo:load", () => {
  // https://github.com/turbolinks/turbolinks/issues/73#issuecomment-460028854
  const googleJsDefinedOnPage = typeof(gtag) == "function"
  const googleAnalyticsTrackingID = process.env.GOOGLE_ANALYTICS_TRACKING_ID || false
  const googleAdWordsTrackingID = process.env.GOOGLE_ADWORDS_TRACKING_ID || false

  if (!googleJsDefinedOnPage) {
    // https://developers.google.com/tag-platform/tag-manager/web/datalayer
    console.log("Google Tag Manager JS in not defined on page.");
  }

  if (googleJsDefinedOnPage && googleAnalyticsTrackingID) {
    // Google Analytics is turned on
    gtag("config", googleAnalyticsTrackingID)
  } else {
    console.log("Google Analytics is turned off.");
  }

  if (googleJsDefinedOnPage && googleAdWordsTrackingID) {
    // Google AdWords is turned on
    gtag("config", googleAdWordsTrackingID, {
      "page_title": document.title,
      "page_path": location.href.replace(location.origin, ""),
    })
  } else {
    console.log("Google AdWords is turned off.");
  }
})
