import iziToast from 'izitoast'
import '../css/modules/izitoast.scss'

document.addEventListener('turbo:load', () => {
  const toast = document.querySelector('.js-toast')
  if (toast && toast.dataset.toastMessage !== '') {
    showToast(toast.dataset.toastMessage, toast.dataset.toastType)
  }
})

function showToast(mes, type) {
  const options = {
    message: mes,
    position: 'topRight',
    transitionIn: 'bounceInDown',
    timeout: 6000,
  }
  switch(type) {
    // most common rails flash message
    case 'notice':
      iziToast.success({
        ...options,
        color: '#6EE7B7' // tw green 300
      })
      break
    case 'success':
      iziToast.success(options)
      break
    case 'warning':
      iziToast.warning(options)
      break
    // error flash message
    case 'alert':
      iziToast.error({
        ...options,
        color: '#FCA5A5' // tw red 300
      })
      break
    case 'error':
      iziToast.error(options)
      break
    case 'info':
      iziToast.info(options)
      break
    default:
      iziToast.info(options)
  }
}
