// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import "@hotwired/turbo-rails"
// import * as ActiveStorage from "@rails/activestorage"
// import "channels"

Rails.start()
// ActiveStorage.start()
require("trix")
require("@rails/actiontext")

const requireCss = require.context('../src/css/autoload', false, /\.(css|scss|sass)$/)
requireCss.keys().forEach(requireCss)

const requireJs = require.context('../src/js', false, /\.(js)$/)
requireJs.keys().forEach(requireJs)
