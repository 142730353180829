document.addEventListener('turbo:load', () => {
  initLoadMore()
})

const initLoadMore = () => {
  const next = document.querySelector(".js-pagination .page.next")
  if (next && !next.classList.contains('disabled')) {
    next.addEventListener('click', (evt) => {
      evt.preventDefault()
      const link = evt.currentTarget.children[0].href
      fetch(link)
        .then(res => res.text())
        .then(text => {
          const parser = new DOMParser()
          const full = parser.parseFromString(text, "text/html")
          const entries = full.querySelector(".js-blog__grid").innerHTML
          const pagination = full.querySelector(".js-pagination").innerHTML
          document.querySelector('.js-blog__grid').insertAdjacentHTML('beforeend', entries)
          document.querySelector('.js-pagination').innerHTML = pagination
          initLoadMore()
        })
    })
  }
}
