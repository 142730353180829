document.addEventListener('turbo:load', () => {
  const chevrons = document.body.querySelectorAll('.js-ordsection__chevron')
  const hiddens = document.body.querySelectorAll('.js-ordsection__hidden')

  // accordions
  if (hiddens && chevrons) {
    chevrons.forEach(el => {
      el.addEventListener('click', evt => {
        const cHid = evt.target.closest('.js-ordsection').querySelector('.js-ordsection__hidden')
        hiddens.forEach(hid => {
          if (hid !== cHid) {
            hid.classList.remove('ordsection__hidden_open')
          }
        })
        chevrons.forEach(chev => {
          if (chev !== evt.currentTarget) {
            chev.classList.remove('ordsection__chevron_open')
          }
        })
        evt.currentTarget.classList.toggle('ordsection__chevron_open')
        cHid.classList.toggle('ordsection__hidden_open')
      })
    })
  }

  const typeSelect = document.body.querySelector('.js-type-select')

  // locations switch
  const locations = document.body.querySelectorAll('.js-ordsection')
  const citySelect = document.body.querySelector('.js-city-select')
  if (locations && citySelect) {
    citySelect.addEventListener('change', () => {
      if (citySelect.value === 'All locations') {
        locations.forEach(loc => {
          loc.classList.remove('d-none')
        })
      } else {
        locations.forEach(loc => {
          const city = loc.querySelector('.js-city')
          if (city.innerText === citySelect.value) {
            loc.classList.remove('d-none')
          } else {
            loc.classList.add('d-none')
          }
        })
      }
      // handling empty message for audit
      const auditSections = document.body.querySelectorAll('.js-audit .js-ordsection')
      const auditSectionsHidden = document.body.querySelectorAll('.js-audit .js-ordsection.d-none')
      const emptyMes = document.body.querySelector('.js-emptymes')
      const data = document.body.querySelector('.js-data')
      if (auditSections.length === auditSectionsHidden.length && typeSelect.value === 'Audits') {
        emptyMes.classList.remove('d-none')
        data.classList.add('d-none')
      } else {
        emptyMes.classList.add('d-none')
        data.classList.remove('d-none')
      }
    })
  }

  // type switch
  if (typeSelect) {
    const benchs = document.body.querySelectorAll('.js-bench')
    const audits = document.body.querySelectorAll('.js-audit')
    typeSelect.addEventListener('change', () => {
      if (typeSelect.value === 'Benchmarking') {
        document.body.querySelector('.js-data').classList.remove('d-none')
        benchs.forEach(bench => bench.classList.remove('d-none'))
        audits.forEach(audit => audit.classList.add('d-none'))
      } else if (typeSelect.value === 'Audits') {
        // handling empty message for audit
        const auditSections = document.body.querySelectorAll('.js-audit .js-ordsection')
        const auditSectionsHidden = document.body.querySelectorAll('.js-audit .js-ordsection.d-none')
        const emptyMes = document.body.querySelector('.js-emptymes')
        const data = document.body.querySelector('.js-data')
        if (auditSections.length === auditSectionsHidden.length) {
          emptyMes.classList.remove('d-none')
          data.classList.add('d-none')
        } else {
          emptyMes.classList.add('d-none')
          data.classList.remove('d-none')
        }
        benchs.forEach(bench => bench.classList.add('d-none'))
        audits.forEach(audit => audit.classList.remove('d-none'))
      }
    })
  }

  // hover menu
  const menuBtn = document.body.querySelector('.js-menu__button')
  if (menuBtn) {
    const menuHover = document.body.querySelector('.js-menu__hover')
    menuBtn.addEventListener('click', () => {
      menuHover.classList.toggle('d-none')
    })
  }

})
