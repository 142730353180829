document.addEventListener('turbo:load', () => {
  // switch tabs
  const tabs = document.querySelectorAll('.js-quote__tab')
  if (tabs) {
    tabs.forEach(el => {
      el.addEventListener('click', evt => {
        // to operate only with current nav
        tabs.forEach(tab => tab.classList.remove('quote__tab_active'))
        evt.target.classList.add('quote__tab_active')
        const note = document.querySelector('.js-quote__note')
        // hidden field to indicate audit form
        const auditField = document.querySelector('.js-audit-field')
        if (note && evt.target.classList.contains('js-audit')) {
          note.classList.remove('d-none')
          firstStep()
          auditField.value = 'true'
        } else {
          note.classList.add('d-none')
          firstStep()
          auditField.value = null
        }
      })
    })
  }

  // go to next step
  const nextBtn = document.querySelector('.js-quote__next')
  if (nextBtn) {
    nextBtn.addEventListener('click', () => {
      secondStep()
    })
  }

  // go to previous step
  const prevBtn = document.querySelector('.js-quote__prev')
  if (prevBtn) {
    prevBtn.addEventListener('click', () => {
      firstStep()
    })
  }

})

const secondStep = () => {
  document.querySelectorAll('.js-quote__first-step').forEach(el => el.classList.add('d-none'))
  document.querySelectorAll('.js-quote__second-step').forEach(el => el.classList.remove('d-none'))
}

const firstStep = () => {
  document.querySelectorAll('.js-quote__first-step').forEach(el => el.classList.remove('d-none'))
  document.querySelectorAll('.js-quote__second-step').forEach(el => el.classList.add('d-none'))
}
