document.addEventListener("turbo:load", () => {
  const mailTo = document.querySelector('.js-mail-to')
  if (mailTo) {
    mailTo.addEventListener('click', () => {
      fetch('/refer')
        .then(response => {
          if (!response.ok) throw response // moving flow to catch block
          console.log('ok')
        })
        .catch(error => console.error("we got an error:", error))
    })
  }
})
