document.addEventListener('turbo:load', () => {
  const field = document.querySelector('.js-file-field')
  if (field) {
    field.addEventListener('change', () => {
      const target = document.querySelector('.js-target')
      const file = field.files[0]
      const img = document.createElement('img')
      img.src = URL.createObjectURL(file)
      img.classList.add('upload-thumb')
      target.innerHTML = null
      target.append(img)
    })
  }
})
