document.addEventListener('turbo:load', () => {
  // switch nav tabs
  const navs = document.querySelectorAll('.js-home__step-nav')
  const steps = document.querySelectorAll('.js-home__steps')
  if (navs && steps) {
    navs.forEach(el => {
      el.addEventListener('click', evt => {
        navs.forEach(tab => tab.classList.toggle('home__step-nav_active'))
        steps.forEach(tab => tab.classList.toggle('d-none'))
      })
    })
  }

})
