import Splide from '@splidejs/splide'
import '../css/modules/splide.scss'

document.addEventListener('turbo:load', () => {
  if (document.querySelector('.js-splide-testi')) {
    initSplideTesti()
  }
})

const initSplideTesti = () => {
  window.splideHero = new Splide('.js-splide-testi', {
    autoplay: true,
    type: 'loop',
    gap: '5px',
    perMove: 1,
    perPage: 1,
    arrows: false,
    autoWidth: true,
  }).mount()
}

document.addEventListener('turbo:before-cache', () => {
  if (window.splideHero) {
    window.splideHero.destroy()
  }
})
