import Typed from "typed.js"

document.addEventListener('turbo:load', () => {
  const typedElem = document.querySelector('.js-typed')
  if (typedElem) {
    new Typed('.js-typed', {
      strings: ['Benchmarking', 'Audits', 'Compliance'],
      startDelay: 1500,
      typeSpeed: 50,
      backDelay: 2000,
      backSpeed: 50,
      loop: true,
    })
  }
})
