document.addEventListener('turbo:load', () => {
  const stripeBlock = document.querySelector('.js-stripe-block')
  if (stripeBlock) {
    const stripe = Stripe(process.env.BAY_STRIPE_PUB)

    const amountField = document.querySelector('.js-amount')
    amountField.addEventListener('input', () => {
      changeTotal(amountField.value)
    })

    document.querySelector('.js-pricecard__btn').addEventListener('click', () => {
      const amount = amountField.value
      fetch( `/checkout${(amount && parseInt(amount) > 1 && parseInt(amount) <= 10) ? '?amount=' + amount : ''}`, {
        method: "POST",
      })
        .then(function (response) {
          return response.json()
        })
        .then(function(session) {
          return stripe.redirectToCheckout({ sessionId: session.id })
        })
        .then(function(result) {
          // If redirectToCheckout fails due to a browser or network
          // error, you should display the localized error message to your
          // customer using error.message.
          if (result.error) {
            alert(result.error.message)
          }
        })
        .catch(function (error) {
          console.error("Error:", error)
        })
    })
  }
})

changeTotal = amount => {
  const totalHolder = document.querySelector('.js-total')
  const amountFinal = (parseInt(amount) >= 1 && parseInt(amount) <= 10) ? amount : 1
  // TODO: change input if too big number entered
  totalHolder.innerText = amountFinal * parseInt(process.env.BAY_BENCH_PRICE)
}
