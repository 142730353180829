document.addEventListener('turbo:load', () => {
  const menuBtn = document.querySelector('.js-header__menubtn')
  const menu = document.querySelector('.js-menu')

  menuBtn.addEventListener('click', () => {
    menu.classList.toggle('menu_open')
  })

  const header = document.querySelector('.header')
  document.body.addEventListener('click', (event) => {
    if (!header.contains(event.target)) {
      setTimeout(() => {
        menu.classList.remove('menu_open')
      }, 100)
    }
  })

  window.addEventListener('resize', () => {
    menu.classList.remove('menu_open')
  })
})
